// @import "ag-grid-community/dist/styles/ag-grid.css";
// @import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";

  
:root {
    --font-size: 14px;
    --font-family: 'OpenSans-Regular';
}
html {
    font-size: 1rem;
    height: 100%;
}
.main-grid{
    padding-top: 7px;
    .tools{
        .p-button{
            padding: 10px
        }
    }
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: var(--font-size);
    .p-component{
        font-size: var(--font-size);
    }
}
.app-body{
    height: calc(100% - 62px);
    &>.main{
        height: 100%;
        padding-top: 121px;
        &>.grid{
            height: calc(100% - 62px);
        }
        &.grid-fixed{
            .main-grid{
                position: fixed;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 999;
                background: #FFF;
                padding-top: 0px;
            }
        }
    }
}
.border-right {
    border-right: 1px solid #ccc !important;
}
.main-grid{
    .breadcrumb{
        padding-top: 0px;
        padding-bottom: 0px;
        .col-8{
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
}

.p-component{
    font-family: var(--font-family);
}
.active1 {
   color: var(--text-color);
}


/* for all */
.ml-12{
    margin-left: 12px;
}
.mr-15{
    margin-right: 15px;
}

/* #tool-bar tool bar */
.p-toolbar{
    padding: 15px 20px;
    border-radius: 0;
}

/* #p-menubar */
.p-menubar-root-list{
    .p-menuitem{
        .p-menuitem-icon{
            font-size: 15px;
        }
        .p-menuitem-link{
            border-radius: 0px !important;
            position: relative;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: transparent;
            &:focus{
                background: none;
            }
            // &::after{
            //     content: '';
            //     height: 1px;
            //     width: 100%;
            //     top: 100%;
            //     left: 0px;
            //     position: absolute;
            // }
        }
    }
    &>.p-menuitem-active{
        &>.p-menuitem-link{
            background: none !important;
        }
    }
}
.p-menubar {
    padding: 0;
    display: flex;
    justify-content: end;
    border: none;
    .p-menubar-root-list{
        .p-submenu-list{
            padding-top: 0;
            padding-bottom: 0;
            border-radius: 4px;
            margin-top: 4px;
            overflow: hidden;
            width: auto;
            z-index: 3;
            &>li{
                &>a{
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 13px;
                    min-height: 40px;
                    span{
                        font-weight: 500;
                    }
                }
            }
        }
        .p-menuitem{
            position: static;
        }
    }
}

/* #grid */
.main-grid{
    padding-left: 10px;
    padding-right: 10px;
    .ag-center-cols-viewport{
        .ag-row{
            .ag-cell{
                input[type="checkbox"]{
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
.grid.main-grid{
    margin-left: 0px;
    margin-right: 0px;
}

/* #p-breadcrumb */
.p-breadcrumb{
    background: none;
    box-shadow: none;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    li{
        font-size: 8px;
        font-weight: 500;
        a{
            .p-menuitem-text{
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

/* #p-tree */
.p-tree{
    .p-treenode-content{
    }
}
.p-tree {
    border-radius: 4px;
    border: none;
    padding: 15px 15px;
    height: 100%;
    .p-tree-wrapper{
        border-radius: 4px;
        padding: 15px 0px;
    }
    .p-tree-container {
        .p-treenode {
            padding-top: 0px;
            padding-bottom: 0px;
            .p-treenode-content{
                padding-top: 0px;
                padding-bottom: 0px;
                transition: all 0.2s;
                .p-tree-toggler{
                    .pi-chevron-down{
                        transform: rotate(90deg);
                        transition: all 0.4s;
                        &::before{
                            content: "\e901";
                        }
                    }
                    .pi-chevron-right{
                        transform: rotate(0deg);
                        transition: all 0.4s;
                    }
                    &:enabled:hover{
                        background: none;
                        // .pi-chevron-down{
                        //     transform: rotate(0deg);
                        //     transition: all 0.4s;
                        // }
                        // .pi-chevron-right{
                        //     transform: rotate(90deg);
                        //     transition: all 0.4s;
                        // }
                    }
                }
                &:hover{
                    transition: all 0.2s;
                }
                .p-tree-toggler-icon{
                    font-size: 12px;
                    font-weight: 500;
                }
                .p-treenode-icon{
                    font-size: 13px;
                }
                // &[aria-expanded="true"]{
                //     .p-tree-toggler{
                //         transform: rotate(0deg);
                //         transition: all 0.4s;
                //         &:enabled:hover{
                //             transform: rotate(0deg);
                //             transition: all 0.4s;
                //         }
                //     }
                // }
            }
        }
    }
}

/*#p-datatable-header */
.p-datatable-gridlines{
    .p-datatable-header{
        border-radius: 4px;
        margin-bottom: 15px;
        padding: 15px !important;
        .p-multiselect-label{
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}

/* #p-datatable  */
.p-datatable {
    .p-datatable-thead {
        &> tr {
            &> th{
                &:first-child{
                    border-radius: 4px 0px 0px 0px;
                }
                &:last-child{
                    border-radius: 0px 4px 0px 0px;
                }
                // border: none;
                padding: 22px 24px !important;
            }
        }
    }
    .p-datatable-tbody{
        &> tr.p-selectable-row {
            &>td{
                // border: none;
                padding: 14px 24px;
            }
        }
    }
}
.main{
    .grid{
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        justify-content: flex-end;
        &>.col-2{
            position: fixed;
            left: 0px;
            z-index: 3;
            top: 0;
            bottom: 0px;
        }
    }
    &>.grid{
        &>.col-10,
        &>.col-2{
            padding: 0px
        }
        .main-grid{
            padding-right: 0px;
            &>.right{
                padding-right: 0px;
            }
        }
    }
    .sidebarBody{
        position: fixed;
        background: #fff;
        width: 100%;
        z-index: 4;
        top: 70px;
    }
}

    /* #p-paginator-bottom */
.p-datatable{
    .p-paginator-bottom{
        justify-content: end;
        font-size: 12px;
        .p-paginator-pages{
            font-size: 14px;
            .p-paginator-element{
                width: 24px;
                height: 24px;
                padding: 0px;
                line-height: 25px;
                border-radius: 4px;
                min-width: 0px;
                font-size: 14px;
                .p-paginator-icon{
                    font-size: 12px;
                }
            }
        }
        .p-paginator-rpp-options{
            height: auto;
            border: none;
            .p-dropdown-label{
                padding-top: 3px;
                padding-bottom: 2px;
            }
        }
        .p-paginator-pages{
            .p-paginator-page{
                width: 24px;
                height: 24px;
                padding: 0px;
                line-height: 25px;
                border-radius: 4px;
                min-width: 0px;
                font-size: 14px;
            }
        }
    }
}

/*#paginator */
.paginator{
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 0px 0px 4px 4px;
    .p-paginator-element{
        width: 24px;
        height: 24px;
        padding: 0px;
        line-height: 25px;
        border-radius: 4px;
        min-width: 0px;
        font-size: 14px;
        .p-paginator-icon{
            font-size: 12px;
        }
        &.p-highlight{
            
        }
    }
    .p-paginator-rpp-options{
        height: auto;
        border: none;
        .p-dropdown-label{
            padding-top: 3px;
            padding-bottom: 2px;
            font-size: 14px;
        }
        .p-dropdown-trigger-icon{
            font-size: 12px;
        }
    }
    .p-paginator-pages{
        .p-paginator-page{
            width: 24px;
            height: 24px;
            padding: 0px;
            line-height: 25px;
            border-radius: 4px;
            min-width: 0px;
            font-size: 14px;
        }
    }
}

/* #breadcrumb */
.breadcrumb{
    h3{
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 500;
    }
    .p-breadcrumb{
        &>ul{
            &>li{
                margin-left: 2px;
                &>a{
                    &:focus{
                        background: none;
                    }
                    padding-left: 0px;
                }
            }
        }
    }
}

/* #grid-default */
.grid-default{
    border-radius: 0px 0px 4px 4px;
    .ag-header{
        border-bottom: none;
    }
    .ag-root-wrapper{
        border: none;
        .ag-header-row{
         .ag-header-cell{
             .ag-header-cell-label{
                 .ag-header-cell-text{
                    font-weight: 500;
                    white-space: break-spaces;
                 }
             }
             &:first-child{
                border-radius: 4px 0px 0px 0px;
             }
             &:last-child{
                border-radius: 0px 4px 0px 0px;
             }
         }   
        }
    }
    &.border{
        border: 1px solid #f3f3f3;
    }
    &.border-top{
        border-top: 1px solid #f3f3f3;
    }
    &.border-bottom{
        border-bottom: 1px solid #f3f3f3;
    }

    // .ag-body-viewport{
    //     overflow-y: auto;
    //     &::-webkit-scrollbar {
    //         width: 8px;
    //         // padding-right: 11px;
    //         border-left-width: 11px;
    //         cursor: pointer;
    //         &:hover{
    //             cursor: pointer;

    //         }
    //       }
    //       /* Track */
    //     &::-webkit-scrollbar-track {
    //         border-radius: 2px;
    //         cursor: pointer;
    //     }
        
    //     /* Handle */
    //     &::-webkit-scrollbar-thumb {
    //         border-radius: 2px;
    //     }
    // }
    // .ag-body-horizontal-scroll-viewport{
    //     &::-webkit-scrollbar {
    //         cursor:pointer;
    //         height: 8px;
    //       }
    //       /* Track */
    //     &::-webkit-scrollbar-track {
    //         border-radius: 2px;
    //     }
        
    //     /* Handle */
    //     &::-webkit-scrollbar-thumb {
    //     border-radius: 2px;
    //     }
    // }
}
.ag-body-viewport{
    .ag-row-level-1{
        .ag-details-row{
            padding: 0px 30px;
        }
    }
}
.ag-pinned-right-header{
    right: -9px;
}
/* #scroll */
.ag-virtual-list-viewport,
.p-dropdown-items-wrapper{
    &::-webkit-scrollbar {
        width: 8px;
      }
      /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 2px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
    }
}
.border-right {
    border-right: 1px solid rgb(221, 226, 235) !important;
}
.p-breadcrumb{
    padding-left: 0px;
}

.img-theme .p-avatar{
    &.p-avatar-image{
        border-radius: 100%;
        padding: 5px;
    }
}
.profile{
    .name{
        line-height: 1;
        &:hover{
            cursor: pointer;
        }
    }
}
.img-theme{
    &.icon{
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: block;
        line-height: 40px;
        text-align: center;
        margin-right: 12px;
        &.evon{
            line-height: 37px;
        }
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            cursor: pointer;
        }
    }
}
.img-avatar{
    width: 32px;
    height: 32px;
}
.logo{
    max-height: 30px;
}

.p-card{
    border: none;
    box-shadow: none;
}
/* default */
.p-selectbutton{
    .p-button{
        border: none;
        border-radius: 4px !important;
        -moz-border-radius: 4px !important;
        -webkit-border-radius: 4px !important;
    }
    &.two-buttons{
        .p-button{
            &:first-child{
                margin-right: 15px;
            }
        }
    }
}
.p-selectbutton .p-button{

}
.btn{
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    height: 38.85px;
    line-height: 38.85px;
    font-size: 14px;
    font-weight: 700;
    padding: 13px;
    text-decoration: none;
    transition: all 0.2s;
    padding-top: 0px;
    padding-bottom: 0px;
    display: inline-block;
    min-width: 95px;
    &:hover{
        cursor: pointer;
        transition: all 0.2s;
    }
    i{
        font-size: 14px;
        margin-right: 12px;
    }
    .btn-default{
        position: relative;
    }
}
.input-group{
    position: relative;
    margin-bottom: 10px;
    label{
        font-size: 14px;
        margin-bottom: 8px;
        display: block;
        line-height: 20px;
    }
    .p-autocomplete{
        width: 100%;
    }
    input{
        height: 35px;
        border-radius: 4px;
        line-height: 35px;
        padding: 0px 27px 0px 12px;
        display: block;
        max-width: 100%;
        width: 100%;
        &:focus-visible{
           outline: none;
        }
        &:focus{
            box-shadow: none !important;
        }
        &:disabled{
            background: rgba(239, 239, 239, 0.3);
            opacity: 1;
        }
    }
    input[type="number"]{
        padding-right: 0px;
    }
    .p-calendar{
        display: block;
        .p-inputtext{
            width: 100%;
        }
    }
    .alert-validation{
        position: absolute;
        top: 100%;
        font-size: 12px;
        margin-top: 3px;
    }
}

.isEmpty {
    visibility: hidden;
}

.search-button{
    .p-button{
        width: 40px;
        height: 40px;
        border-radius: 0px 4px 4px 0px;
    }
}

.p-button-warning{
    .p-button{
        height: 40px;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 40px;
        padding-right: 0px;
    }
}

.p-tree-filter-container{
    input{
        height: 40px;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 40px;
        padding-left: 40px;
    }
    .p-tree-filter-icon{
        left: 18px;
        right: auto !important;
    }
}

.p-menu-list{
    li{
        a{
            padding-top: 7px !important;
            padding-bottom: 7px !important;
            font-size: 14px;
            .p-menuitem-icon{
                margin-right: 15px !important;
            }
            span{
                font-weight: 600;
            }
        }
    }
}
div.p-menu-buttons{
    padding-top: 0;
    padding-bottom: 0;
}
.p-button.p-button-text
.p-button.p-button-text:active,
.p-button.p-button-text:hover,
.p-button.p-button-text:enabled:focus,
.p-button.p-button-text:enabled:active,
.p-button.p-button-text:enabled:hover{
    background: none !important;
}

.header-filter-search{
    .input-group{
        .search-button{
            position: absolute;
            right: 0px;
            top: 0px;
        }
        &.icon{
            input{
                padding-left: 40px;
            }
        }
        .pi-angle-down{
            position: absolute;
            right: 40px;
            top: 0px;
            height: 40px;
            line-height: 40px;
            width: 40px;
            text-align: center;
            transition: all 0.2s;
            &:hover{
                cursor: pointer;
                transition: all 0.2s;
            }
        }
    }
    .placeholder-icon{
        position: absolute;
        top: 20px;
        left: 18px;
        z-index: 9;
    }
}
div{
    &.table-tool{
        margin-left: 0px;
        margin-right: 0px;
    }
}
.table-tool{
    border-radius: 4px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 7px;
    .p-selectbutton{
        .p-button{
            background: none;
            border: 1px solid #d9d9d9;
            border-radius: 0px !important;
            color: rgba(51, 51, 51, 0.6);
            margin-left: -1px;
            border-right: 1px solid #d9d9d9 !important;
            &.p-highlight,
            &:hover{
                // border-color: #228BCC !important;
                position: relative;
                z-index: 2;
                // background: #228BCC;
                span{
                    color: rgb(255, 255, 255);
                }
            }
        }
    }
    .tools{
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: right;
        .btn-plus{
            margin-right: 15px;
        }
    }
    @media screen and (max-width: 767px) {
        .tools,
        .header-filter-search{
            width: 100%;
            justify-content: left;
        }
    }
    .d-flex{
        .input-group{
            margin-bottom: 0;
        }
    }
}
.p-splitbutton-defaultbutton{
    min-width: 100px;
}

/* #select-default */
.select-default{
    margin-bottom: 10px;
    position: relative;
    .alert-validation{
        position: absolute;
        top: 100%;
        font-size: 12px;
        margin-top: 3px;
    }
    label{
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
        line-height: 20px;
    }
    .p-multiselect,
    .p-dropdown{
        width: 100%;
        height: 35px;
        span{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .p-dropdown{
        span{
            padding: 6px 6px;
        }
        &.p-disabled{
            background: rgba(239, 239, 239, 0.3);
            opacity: 1;
        }
    }
    .p-inputwrapper-filled{
        .p-multiselect{
            &.p-multiselect-chip{
                .p-multiselect-label-container{
                    .p-multiselect-label{
                        line-height: 1;
                    }
                }
            }
        }
    }
    .p-multiselect-label{
        line-height: 40px;
        padding: 0px 10px;
        height: 100%;
        .p-multiselect-token-label{
            padding-top: 0px;
            padding-bottom: 0px;
            font-size: 12px;
        }
        .p-multiselect-token{
            padding: 3px 5px;
        }
        .p-multiselect-token-icon{
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .p-multiselect-filter-icon{
        margin-top: -16px;
    }
    .p-multiselect-items-wrapper{
        .p-multiselect-token-icon,
        .p-multiselect-item{
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }
    .p-inputwrapper-filled{
        .p-multiselect-label{
            // line-height: 1;
        }
    }
    .p-multiselect{
        .p-multiselect-label{
            font-size: 16px;
        }
        .p-multiselect-panel{
            .p-checkbox{
                width: 21px;
                height: 21px;
                border-radius: 2px;
                border-width: 3px;
                border-style: solid;
                margin-right: 12px;
                .p-checkbox-box{
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
            .p-multiselect-filter-container{
                .p-multiselect-filter{
                    padding: 10px 30px;
                }
                .p-multiselect-filter-icon{
                    left: 18px;
                }
            }
        }
    }
}
.box-15{
    border-radius: 4px;
    padding: 15px;
    height: 100%;
}
.box-title{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    display: block;
}
.reload-filter{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: inline-block;
    line-height: 40px;
    padding: 7px 0px;
    transition: all 0.2s;
    text-align: center;
    &:hover{
        cursor: pointer;
        transition: all 0.2s;
    }
}
.d-flex{
    display: flex;
    &.d-right{
        justify-content: right;
    }
    .col-30{
        width: calc(100% / 3);
        padding: 0px 10px;
    }
    .col-50{
        width: 50%;
        padding: 0px 10px;
    }
    &.hoz{
        &>div{
            flex: 1 1 auto;
            width: 1%;
        }
    }
}
.header-filter-search{
    .input-group{
        margin-bottom: 0px;
    }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    padding-top: 7px;
    padding-bottom: 7px;
}
.ag-root-wrapper-body{
    .ag-overlay-wrapper{
        .ag-overlay-no-rows-center{
            padding-top: 40px;
        }
    }
}
.product-detail{
    padding-top: 15px;
    padding-bottom: 20px;
    .p-card-body{
        padding: 0px;
        .p-card-content{
            padding-top: 0px;
            padding-bottom: 0px;
            .ng-star-inserted {
                .text-nowrap.label-text {
                    opacity: 0.7;
                }
                .text-default {
                    font-weight: 500;
                }
            }
        }
    }
    .content{
        border-radius: 4px 4px 0px 0px;
        padding: 15px;
        .panel-heading{
            .panel-title{
                margin-top: 0px;
                font-size: 20px;
                font-weight: normal;
                margin-bottom: 10px;
            }
        }
    }
    .col-item{
        padding-left: 8px;
        padding-right: 8px;
    }
    .row{
        margin-left: -8px;
        margin-right: -8px;
    }
    .has-image{
        // &:nth-child(-n+10) {
        //     width: calc(33.33333333% - 72px);  
        // } 
        // &:nth-child(11) {
        //     clear: left;
        //   }
        &.image-iam{
            margin-bottom: 20px;
            // width: 216px;
            .p-image{
                max-width: 100%;
                position: relative;
                overflow: hidden;
                display: block;
                max-width: 200px;
                &::after{
                    content: "";
                    padding-top: 118%;
                    display: block;
                }
                &>img{
                    border-radius: 4px;
                    position: absolute;
                    object-fit: cover;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

}

.checkbox-default{
    margin-bottom: 15px;
    label{
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
        line-height: 20px;
    }
    .p-checkbox-label{
        margin-bottom: 0px;
        &:hover{
            cursor: pointer;
        }
    }
    .p-checkbox-box{
        &.p-highlight{
            .p-checkbox-icon{
                &::before{
                    background: none;
                }
            }
        }
        .p-checkbox-icon{
            &::before{
                display: block !important;
                content: "";
                position: absolute;
                border: none !important;
                transform: none !important;
                transform-origin: unset !important;
                background: url(../src/assets/images/svg/checked.svg) !important;
                width: 10px;
                height: 11px;
                top: 1px !important;
                left: 2px !important;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                z-index: 1;
                animation: none !important
            }
        }
    }
    .p-checkbox{
        width: 19px;
        height: 19px;
        box-shadow: none !important;
        &:hover{
            box-shadow: none;
        }
    }
    .checkbox-content{
        height: 40px;
        display: flex;
        align-items: center;
    }
}
.slider-default{
    label{
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
        line-height: 20px;
    }
    .slider-in{
        padding-left: 10px;
        padding-right: 10px;
    }
}
.left-sidebar{
    .p-menu{
        .p-menu-list{
            li{
                a{
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                }
            }
            .p-submenu-header{
                text-transform: uppercase;
                font-weight: 600;
                margin-top: 10px;
                &:first-child{
                    margin-top: 0px;
                }
            }
            span{
                line-height: 1.2em;
            }
            padding-top: 62px;
        }
        max-height: 100vh;
        overflow-y: auto;
    }
}
.ag-header{
    padding-right: 9px;
}
div.menu-agrid{
    width: auto;
    min-width: 147px;
}
.menu-option-right{
    width: auto;
    border-right: 20px solid transparent !important;
    .p-menuitem-text{
        white-space: nowrap;
    }
}
.text-number {
    text-align: right;
}

.header{
    position: fixed;
    z-index: 5;
    left: 0px;
    width: 100%;
}

.bg-edit {
    background-color: rgb(245, 252, 151);
}
.p-datepicker{
    &.hide-narrow{
        .p-datepicker-header{
            .p-datepicker-prev,
            .p-datepicker-next{
                display: none;
            }
        }
    }
    &.hide-month-select{
        .p-datepicker-title{
            .p-datepicker-month{
                display: none;
            }
        }
    }
    &.datepicker-default{
        .p-datepicker-title{
            select{
                height: 40px;
                border-radius: 4px;
                padding-left: 10px;
                padding-right: 10px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    &.datepicker-for-year{
        .p-datepicker-title{
            width: 100%;
            select{
                width: 100%;
            }
        }
    }
}
.header-filter-search{
    &.button-filter{
        .p-button{
            margin-right: 5px;
        }
    }
}

.p-menubar {
    .p-menubar-root-list{
        & > .p-menuitem {
            &>.p-menuitem-link{
                .p-menuitem-text{
                    font-weight: 600;
                }
            }
        }
    }
}
#myGrid{
    .ag-floating-filter-input{
        .ag-input-field-input{
            height: 30px;
            border-radius: 4px;
        }
    }
}

.ag-floating-filter-button-button, .ag-header-cell-menu-button {
}
.advance-seach{
    .input-group.icon {
        input{
            padding-left: 15px;
        }
    }
}

.overlay-select-month{
        .p-overlaypanel-content{
            max-width: 260px;
            padding: 15px 24px;
        }
    }
    .check-list-months{
        display: flex;
        flex-wrap: wrap;
        .checkbox-default{
            width: 50%;
            align-items: center;
            display: flex;
            label{
                margin-bottom: 0px;
                margin-left: 12px;
            }
        }
    }
    .select-month{
        .p-dropdown{
            background: #F4F5F9;
            border-radius: 4px;
            border: none;
            width: 100%;
            margin-bottom: 15px;
            .p-dropdown-label{
                color: #283991;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
    .filters{
        .dates{
            background: #F4F5F9;
            .p-button{
                background: #F4F5F9;
                color: #283991;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    width: 1px;
                    height: 30px;
                    background: rgba(51, 51, 51, 0.2);
                    margin-left: 10px;
                }
                .label{
                    margin-left: 14px;
                    margin-right: 14px;
                }
            }
        }
    }
    .months-selectd{
        ul{
            padding-left: 0px;
            margin: 0px;
            li{
                margin: 0px;
                display: inline-block;
                margin-right: 8px;
                line-height: 1;
                .month{
                    background: #FFFFFF;
                    border-radius: 16px;
                    padding: 6px 10px;
                    label{
                        color: #283991;
                        margin-right: 8px;
                    }
                    .delete{
                        &:hover{
                            cursor: pointer;
                            path{
                                fill: red;
                            }
                        }
                        svg{
                            margin-bottom: -2px;
                        }
                    }
                }
            }
        }
    }
    .filters{
        .dates{
            display: flex;
            align-items: center;
        }
        background: #fff;
        padding: 20px 15px;
    }


    .p-card {
        border: 1px solid #ccc;
    }
.tur-money{
    .p-card{
        border: none;
        height: 100%;
    }
    .p-card-body{
        background: #FFFFFF;
        border: 1px solid rgba(51, 51, 51, 0.05);
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
        border-radius: 4px;
        height: 100%;
    }
    .p-card-content{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .symbol{
        background: #29266317;
        width: 32px;
        height: 32px;
        display: inline-block;
        text-align: center;
        line-height: 39px;
        border-radius: 4px;
    }
    .money{
        font-weight: 500;
        font-size: 26px;
        margin-right: 5px;
    }
    .cur{
        color: rgba(51, 51, 51, 0.6);
        font-size: 20px;
    }
    .in{
        position: relative;
        color: rgba(51, 51, 51, 0.6);
        .right{
            padding-left: 50px;
            line-height: 1;
            &>div{
                &:first-child{
                    margin-bottom: 10px;
                }
            }
        }
        .left{
            position: absolute;
        }
    }
}
.dashboard-charts{
    .p-card{
        background: #FFFFFF;
        border: 1px solid rgba(51, 51, 51, 0.05);
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
        border-radius: 4px;
        padding: 15px;
    }
    .p-card-header{
        font-weight: 500;
        font-size: 20px;
    }
    .draw-chart{
        h4{
            font-weight: 500;
            font-size: 20px;
            margin: 0px;
        }
        .header-chart{
            justify-content: space-between;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
    }
    .chart{
        height: 100%;
        .p-card{
            height: 100%;
            .p-card-content{
                width: 100%;
                padding: 0;
            }
        }
    }
    .p-card-body{
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 0px;
    }
}
.grid-full-height{
    .ag-root-wrapper{
        height: 100%;
        .ag-root-wrapper-body{
            height: 100%;
        }
    }
}
.legend-default{
    ul{
        padding: 0px;
        margin: 0px;
        display: flex;
        justify-content: end;
        li{
            list-style: none;
            display: flex;
            align-items: center;
            margin-right: 12px;
            &:hover{
                cursor: pointer;
            }
            span{
                font-size: 12px;
                margin: 0px;
                color: rgba(51, 51, 51, 0.6);
                &:first-child{
                    width: 14px;
                    height: 4px;
                    border-radius: 75px;
                    margin-right: 5px;
                    display: inline-block;
                }
            }
        }
    }
}
.p-button{
    &.p-button-sm{
        font-size: 12.25px;
        padding: 6px 9px
    }
    &.p-button{
        font-size: 14px;
        padding: 7px 10px;
    }
    &.p-button-lg{
        font-size: 17px;
        padding: 9px 13px;
    }
}
.page-detail{
    .p-card{
        border: none;
    }
}
.wrap-link{
    label{
        font-size: 14px;
        margin-bottom: 8px;
        display: block;
        line-height: 20px;
    }
}
.noti-number{
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    padding: 3px 6px;
    line-height: 1;
    &.bg-secondary {
        background: #6c757d;
        border: 1px solid #6c757d;
    }
    &.bg-danger{
        background: #c82333;
        color: #ffffff;
    }
    &.bg-warning{
        background: #ffc107;
        color: #ffffff;
    }
    &.bg-success{
        color: #ffffff;
    background: #28a745;
    }
    &.bg-dark{
        color: #ffffff;
    background: #1A1A40
    }
    &.bg-info{
        color: #ffffff;
    background: #28a745;
    }
}
.textarea-default{
    label{
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
        line-height: 20px;
    }
    textarea{
        border: 1px solid #D9DFF0;
        border-color: #D9DFF0;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        min-height: 100px;
        font-family: Arial;
        &:disabled{
            background: #efefef;
            border: 1px solid rgba(239, 239, 239, 0.3);
        }
    }
    margin-bottom: 10px;
}
.group-textarea{
    margin-bottom: 12px;
    position: relative;
    label{
        color: #808AA6;
        font-size: 12px;
        position: absolute;
        left: 12px;
        transition: 300ms ease all;
        top: 8px;
        z-index: 1;
    }
    textarea{
        border: 1px solid #D9DFF0;
        border-color: #D9DFF0;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        min-height: 100px;
        padding: 30px 12px 4px 11px;
        font-family: Arial;
        &:disabled{
            background: #efefef;
            border: 1px solid rgba(239, 239, 239, 0.3);
            &:hover{
                border: none;
            }
        }
        &:hover,
        &:focus-visible{
            border: 1px solid #292663;
            outline: none;
        }
    }
    margin-bottom: 10px;
}
.p-static{
    position: static !important;
}
.dropwdown-profile{
    &.p-menu{
        top: 100% !important;
        margin-top: 0px !important;
    }
}
.wrap-image{
    position: relative;
    max-width: 200px;
    .upload{
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
    .upload:hover{
        cursor: pointer;
    }
}
.alert-validation{
    &.alert-danger{
        color: red
    }
}

.product-detail .khachHangWrapper .panel-heading .panel-title{
    margin-bottom: 10px;
}
.delete_avatar{
    position: absolute;
    top: 3px;
    right: 3px;
    color: #fff;
    background: red;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: block;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    z-index: 9;
    .pi{
        font-size: 14px;
    }
}

.group-field{
    margin-bottom: 20px;
    position: relative;
    label{
        color: #808AA6;
        font-size: 12px;
        position: absolute;
        left: 12px;
        -webkit-transition: 300ms ease all;
        transition: 300ms ease all;
        top: 20px;
    }
    &.valid{
       label{
            top: 8px;
            z-index: 2;
        }

    }
    &.invalid{
       label{
            top: 20px;
        }
        &.focused{
            label{
                top: 8px;
            }
        }
    }
    input{
        width: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        background: none;
        border: 1px solid rgba(51, 51, 51, 0.1);
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        padding-left: 12px;
        padding-right: 12px;
        outline: none;
        line-height: 20px;
        padding: 30px 12px 4px 11px;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            // -webkit-box-shadow: 0 0 0 30px rgb(87 94 131) inset !important;
            // color: #ffffff !important;
            // -webkit-text-fill-color: #fff;

        }
        position: relative;
        z-index: 1;
        &:hover,
        &:focus{
            border: 1px solid #292663;
        }
        &:disabled{
            background: rgba(239, 239, 239, 1);
            border: 1px solid rgba(239, 239, 239, 0.3);
            z-index: 0;
        }
    }
    a{
        color: #fff;
        text-decoration: none;
    }
    &.regiter_forgot{
        margin-top: 16px;
        font-size: 14px;
        a:first-child{
            margin-right: 16px;
            position: relative;
            &:after{
                content: '';
                display: block;
                height: 28px;
                background: rgba(255, 255, 255, 0.4);
                position: absolute;
                width: 1px;
                right: -17px;
                top: -4px;
            }
        }
        a:last-child {
            margin-left: 16px;
        }
    }
    .icon-down{
        position: absolute;
        right: 20px;
        top: 19px
    }
    &.has-button{
        display: flex;
        .p-button{
            height: 100%;
        }
    }
    &.field-currency{
        &.invalid{
           label{
            z-index: 2;
           }
        }
        label{
            // top: 8px;
        }
    }
    &.nomal-field{
        input{
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    &.default{
        label{
            top: 8px;
        }
    }
    &.normal{
        label{
            font-size: 12px;
            position: absolute;
            left: 12px;
            -webkit-transition: 300ms ease all;
            transition: 300ms ease all;
            top: auto
        }
        input{
            &:valid + label{
                top: 8px;
            }
            &:invalid + label{
                top: 20px;
            }
            &:focus + label { 
                top: 8px;
                -webkit-transition: 300ms ease all;
              transition: 300ms ease all;
             }
        }
    }
}

.group-date{
    margin-bottom: 20px;
    position: relative;
    label{
        color: #808AA6;
        font-size: 12px;
        position: absolute;
        left: 12px;
        -webkit-transition: 300ms ease all;
        transition: 300ms ease all;
        top: 8px;
        z-index: 1;
    }
    // &.valid{
    //     label{
    //          top: 8px;
    //      }
    //  }
    //  &.invalid{
    //     label{
    //          top: 20px;
    //      }
    //  }
    .p-calendar{
        display: block;
        input{
            width: 100%;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            background: rgba(255, 255, 255, 0.3);
            border: 1px solid rgba(51, 51, 51, 0.1);
            font-size: 16px;
            color: rgba(0, 0, 0, 0.87);
            padding-left: 12px;
            padding-right: 12px;
            outline: none;
            line-height: 20px;
            padding: 30px 12px 4px 11px;
            &::-webkit-input-placeholder { /* Edge */
                font-size: 14px;
              }
              
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                font-size: 14px;
              }
              
              &::placeholder {
                font-size: 14px;
              }
        }
        &.p-calendar-disabled{
            input{
                border: 1px solid rgba(239, 239, 239, 0.3);
                background: rgba(239, 239, 239, 1);
                opacity: 1;
            }
        }
    }
}

.group-dropdown{
    margin-bottom: 20px;
    position: relative;
    label{
        color: #808AA6;
        font-size: 12px;
        position: absolute;
        left: 12px;
        -webkit-transition: 300ms ease all;
        transition: 300ms ease all;
        z-index: 2;
        top: 8px; 
    }
    &.valid{
        label{
             top: 8px;
         }
     }
     &.invalid{
        label{
             top: 20px;
         }
     }
    .p-dropdown{
        display: block;
        border: 1px solid rgba(51, 51, 51, 0.1);
        background: none;
        position: relative;
        z-index: 2;
        &.p-disabled{
            background: rgba(239, 239, 239, 1);
            opacity: 1;
            z-index: 0;
        }
        .p-dropdown-label{
            display: block;
            width: 100%;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.87);
            outline: none;
            line-height: 1;
            padding: 30px 12px 4px 11px;
            min-height: 54px;
        }
        .p-dropdown-trigger{
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    select{
        padding: 30px 12px 4px 7px;
        min-height: 54px;
        background: none;
        border: none;
        outline: none;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        width: 100%;
    }
    &.default{
        width: 100%;
        label{
            top: 8px;
        }
    }
    p-dropdown.ng-dirty.ng-invalid {
        &> .p-dropdown{
            border-color: transparent;
        }
    }
}
.group-date,
.group-dropdown,
.group-field{
    .alert-validation{
        position: absolute;
        top: 100%;
        color: #fff;
        left: 0;
        width: 100%;
        border-radius: 3px;
        font-size: 12px;
        padding: 0px 5px;
        font-weight: 500;
        &.alert-danger{
            background: red;
        }
    }
    
}
.group-field{
    input.ng-invalid {
        border: 1px solid unset;
    }
}
.group-date{
    .ng-invalid {
        input{
            border-color: unset !important;
        }
    }
}
.no-label{
    .p-dropdown{
        .p-dropdown-label{
            padding-top: 21px;
        }
    }
    input{
        padding: 17px 12px 17px 11px;
    }
    .p-calendar{
        input{
            padding: 17px 12px 17px 11px;
        }
    }
}
.height-56{
    height: 56px;
}
.ag-root-wrapper{
    .ag-root-wrapper-body{
        .ag-root{
            .ag-body-viewport{
                overflow: auto;
            }
        }
    }
}
.height-100per{
    height: 100%;
}
.tab-customer-detail{
    &.p-tabview {
        .p-tabview-nav {
            li {
                .p-tabview-nav-link{
                    min-height: 52px;
                    padding-left: 28px;
                    padding-right: 28px;
                    i{
                        margin-right: 6px;
                    }
                }
                a{
                    i{
                        position: absolute;
                        left: 5px;
                    }
                }
            }
        }
        span{
            .p-badge{
                position: absolute;
                width: 18px;
                min-width: 0px;
                height: 18px;
                font-size: 12px;
                line-height: 19px;
                right: 5px;
                top: 16px;
            }
        }
    }
}
.p-steps{
    padding-bottom: 0px;
    padding-top: 0px;
    // margin-left: calc((-100%/3)/2);
    ul{
        li{
            .p-menuitem-link{
                display: block;
                padding-top: 0px !important;
                &:focus{
                    background: none !important;
                }
                .p-steps-number{
                    width: 30px;
                    height: 30px !important;
                    margin: auto;
                    position: relative;
                    z-index: 2;
                }
                .p-steps-title{
                    padding-left: 0px !important;
                    margin-top: 10px !important;
                    display: block;
                    font-weight: 500;
                }
            }
            &.active{
                .p-steps-number{
                    // background: #0979FD !important;
                }
                &.p-highlight{
                    .p-steps-number{
                        // background: #0979FD !important;
                    }
                }
            }
        }
    }
}
.sl-steps{
    &.items-3{
        ul{
            li{
                // width: calc(100% / 3);
                text-align: center;
                &::before{
                    position: absolute !important;
                    left: 0 !important;
                    top: 14px !important;
                    content: " " !important;
                    border-top: 2px solid rgba(0, 0, 0, 0.25);
                    display: block !important;
                    width: 50%;
                }
                &::after{
                    position: absolute !important;
                    right: 0 !important;
                    top: 14px !important;
                    content: " " !important;
                    border-top: 2px solid rgba(0, 0, 0, 0.25);
                    display: block !important;
                    width: 50%;
                }
                &.active{
                    .p-steps-title{
                        font-weight: 600;
                    }
                    &::before{
                        // border-top: 2px solid #0979FD;
                    }
                    &::after{
                        // border-top: 2px solid #0979FD;
                    }
                    &.current{
                        &::after{
                            border-top: 2px solid rgba(0, 0, 0, 0.25);
                        }
                    }
                }
                &:first-child{
                    &::before{
                        display: none !important;
                    }
                }
                &:last-child{
                    &::after{
                        display: none !important;
                    }
                }
            }
        }
    }
}
.upload-card-note{
    background: rgba(34, 139, 204, 0.05);
    border-radius: 4px;
    padding: 30px;
    margin-top: 40px;
    .note{
        ul{
            padding-left: 0px;
            li{
                position: relative;
                font-size: 14px;
                color: #212633; 
                padding-left: 17px;
                list-style: none;
                margin-bottom: 4px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    left: 0px;
                    background: #33CCCC;
                    border-radius: 100%;
                    top: 6px;
                }
            }
        }
    }
    .note-truth{
        display: flex;
        .item{
            width: calc(100%/3);
            text-align: center;
            padding: 0px 15px;
            img{
                max-width: 100%;
            }
            span{
                font-size: 14px;
                color: #212633;
            }
        }
        margin-left: -15px;
        margin-right: -15px;
    }
    &::after{
        content: '';
        display: table;
        clear: both;
    }
}

.p-icon-white {
    color: white !important;
}

.p-card-custom{
    margin:5px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px;
    border-radius: 4px;
    padding: 5px;
 }

